<script>
  import AvatarMonthEmployee from "./AvatarMonthEmployee.vue";

  export default {
    props: ["employee", "openVisorByReaction"],
    components: {
      AvatarMonthEmployee,
      Modal: () => import("@/components/modals/ModalPost.vue"),
      Likes: () => import("../reactions/likes/Likes.vue"),
      Views: () => import("../reactions/views/Views.vue"),
      Claps: () => import("../reactions/claps/Claps.vue"),
    },
    computed: {
      showCard() {
        return this.employee?.viewers && this.employee.viewers.some(({ user }) => user._id === this.$user._id);
      },
      isComingSoon() {
        return !this.employee.user;
      },
      dataReactions() {
        return {
          item: this.employee._id,
          component: "monthemployees",
        };
      },
    },
    methods: {
      getUserName() {
        const { user } = this.employee;
        const { artisticName, name, lastname } = user;
        return artisticName ? artisticName : `${name} ${lastname}`;
      },
      getAreaName() {
        const { area } = this.employee;
        return area.name;
      },
    },
  };
</script>

<template>
  <div class="monthEmployeeLargeCard" @click="$emit('openModal')">
    <div class="monthEmployeeLargeCard__wrapper" v-if="showCard">
      <div class="monthEmployeeLargeCard__info">
        <h2 class="monthEmployeeLargeCard__month">{{ employee.period }}</h2>
        <h3 class="monthEmployeeLargeCard__name">{{ getUserName() }}</h3>
        <p class="monthEmployeeLargeCard__area">{{ getAreaName() }}</p>
        <p class="monthEmployeeLargeCard__since">{{ employee.yearsInCompany }}</p>
      </div>
      <div class="monthEmployeeLargeCard__img">
        <AvatarMonthEmployee :employee="employee" @click="$emit('openModal')" class="monthEmployeeLargeCard__avatar" />
        <iconic class="monthEmployeeLargeCard__shadowCard" name="shadowCard"></iconic>
      </div>
      <div class="monthEmployeeLargeCard__reactions">
        <Likes :likes="employee.likers" :showLikes="true" :payload="dataReactions" :openLikes="(data) => openVisorByReaction('likes', data)" />
        <Claps :claps="employee.clapers" :showClaps="true" :payload="dataReactions" :openClaps="(data) => openVisorByReaction('claps', data)" />
        <Views :views="employee.viewers" :showViews="true" :openViews="(data) => openVisorByReaction('views', data)" v-if="$user.role === 'superadmin'" />
      </div>
    </div>
    <div class="monthEmployeeLargeCard__hidden" v-else>
      <div class="monthEmployeeLargeCard__icons">
        <h2 class="monthEmployeeLargeCard__largeMonth">{{ employee.period }}</h2>
        <iconic class="monthEmployeeLargeCard__userSkeleton" name="anonymus" />
        <iconic class="monthEmployeeLargeCard__questionMark" name="questionMark" />
      </div>
      <div class="monthEmployeeLargeCard__skeleton">
        <div class="monthEmployeeLargeCard__one"></div>
        <div class="monthEmployeeLargeCard__two"></div>
        <div class="monthEmployeeLargeCard__three"></div>
        <h2 class="monthEmployeeLargeCard__name" v-if="isComingSoon">coming soon</h2>
        <span class="monthEmployeeLargeCard__click" v-else>Click para ver quien es</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
  .large {
    width: 120px;
    height: 120px;
  }
  .monthEmployeeLargeCard {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(
          342.96deg,
          #91693a 1.71%,
          #a07a43 9.36%,
          #c8a65d 24.68%,
          #dfbf6c 32.34%,
          #b48a42 53.12%,
          #bb944c 59.68%,
          #d1b169 69.53%,
          #f3df96 82.66%,
          #f7e59c 84.84%,
          #e6d08c 87.03%,
          #c7aa6e 91.41%,
          #af8d57 95.78%,
          #9d7846 100.16%,
          #936b3c 103.44%,
          #906739 107.82%
        )
        border-box;
    border: 1px solid transparent;
    border-radius: 12px;
    .date__second {
      display: none;
    }
    &__wrapper {
      position: relative;
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 15px;
      background: url("../../assets/img/BackgroundSlider.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 3%;
      font-family: $newFont_semiBold;
    }
    &__info {
      height: 100%;
      width: 50%;
      display: flex;
      flex-direction: column;
      text-align: left;
      align-items: center;
      justify-content: center;
      gap: 4px;
      font-family: $newFont_semiBold;
    }
    &__img {
      display: flex;
      justify-content: center;
      height: 100%;
      .AvatarMonthEmployee {
        &__image {
          outline: none;
        }
        &__stars {
          gap: 5px;
        }
      }
    }
    &__avatar {
      width: 120px;
      height: 120px;
    }
    &__month {
      width: 100%;
      font-family: $newFont_semiBold;
      font-size: 20px;
    }
    &__name,
    &__largeMonth {
      width: 100%;
      font-family: $newFont_regular;
      font-size: 16px;
    }
    &__largeMonth {
      text-align: center;
    }
    &__area {
      width: 100%;
      font-family: $newFont_bold;
      font-size: 16px;
      color: #2a2a2a;
      text-transform: capitalize;
    }
    &__since {
      width: 100%;
      color: #2a2a2a;
      font-size: 16px;
      font-family: $newFont_regular;
    }
    &__icons {
      font-size: 120px;
      @include Column(center, center);
    }
    &__userSkeleton {
      color: #f6dfdf;
      top: 12px;
      position: relative;
    }
    &__questionMark {
      font-size: 75px;
      position: absolute;
      color: #000;
      top: 100px;
    }
    &__reactions {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      @include Flex(row);
      gap: 15px;
      transform: scale(0.8);
    }
    &__hidden {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      background: url("../../assets/img/BackgroundSlider.png");
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 3%;
      cursor: pointer;
    }
    &__date,
    &__dateSecond {
      color: $primary-color;
      font-family: $newFont_bold;
      font-size: 24px;
    }
    &__dateSecond {
      display: none;
    }
    &__skeleton {
      @include Flex(column);
      align-items: start;
      gap: 12px;
      padding-right: 20px;
    }
    &__one {
      width: 100px;
      height: 14px;
      border-radius: 8px;
      background: rgba(189, 9, 9, 0.14);
    }
    &__two,
    &__three {
      width: 152px;
      height: 10px;
      border-radius: 8px;
      background: rgba(0, 0, 0, 0.14);
    }
    &__click {
      color: #2a2a2a;
      font-family: $newFont_medium;
      font-size: 14px;
    }
    &__shadowCard {
      position: absolute;
      font-size: 100px;
      bottom: -30px;
    }
    @media screen and (min-width: 768px) {
      &__icons {
        left: 30px;
      }
      &__hidden {
        justify-content: center;
      }
      &__wrapper {
        justify-content: space-evenly;
        min-height: 200px;
      }
      &__info {
        width: fit-content;
      }
      &__questionMark {
        top: 70px;
      }
    }
    @media screen and (min-width: 1024px) {
      &__date {
        display: none;
      }
      &__dateSecond {
        display: flex;
        margin-bottom: 40px;
      }
      &__icons {
        padding-bottom: 20px;
      }
      &__hidden {
        flex-direction: column;
      }
      &__wrapper {
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        gap: 48px;
      }
      &__userSkeleton {
        font-size: 420px;
      }
      &__img {
        height: 30%;
        width: 100%;
        align-items: center;
        position: relative;
        top: 40px;
        .AvatarMonthEmployee {
          width: 270px;
          height: 270px;
          &__stars {
            gap: 10px;
          }
          &__star {
            width: 30px;
            height: 30px;
            &--two {
              height: 40px;
              width: 35px;
              margin-bottom: 18px;
            }
          }
          &__container {
            border: 12px solid transparent;
          }
        }
      }
      &__avatar {
        width: 250px;
        height: 250px;
      }
      &__info {
        height: 40%;
        width: 100%;
        text-align: center;
        justify-content: end;
        gap: 20px;
      }
      &__month {
        width: 100%;
        position: absolute;
        top: 24px;
      }
      &__name {
        font-size: 24px;
        font-family: $newFont;
      }
      &__area {
        color: #2a2a2a;
        font-size: 20px;
        font-family: $newFont_bold;
      }
      &__since {
        color: #2a2a2a;
        font-size: 16px;
        font-family: $newFont_regular;
      }
      &__questionMark {
        position: absolute;
        font-size: 200px;
        top: 40%;
      }
      &__skeleton {
        padding-right: 0px;
        align-items: center;
      }
      &__one {
        width: 140px;
      }
      &__two,
      &__three {
        width: 210px;
      }
      &__shadowCard {
        font-size: 160px;
        bottom: -200px;
      }
    }
    @media screen and (min-width: 1440px) {
      .AvatarMonthEmployee {
        width: 300px;
        height: 300px;
        &__stars {
          left: 95px;
        }
      }
      &__questionMark {
        left: 22%;
      }
    }
  }
</style>
